/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Grid } from 'theme-ui';
import SectionHeader from 'components/section-header';
import FeatureCard from 'components/feature-card.js';
import Smart from 'assets/feature/smart.svg';
import Winner from 'assets/feature/winner.svg';
import Cloud from 'assets/feature/cloud.svg';
import Setting from 'assets/feature/setting.svg';
import Design from 'assets/feature/design.svg';
import Chat from 'assets/feature/chat.svg';

const data = [
  {
    id: 1,
    imgSrc: Smart,
    altText: 'Part Discovery',
    title: 'Part Discovery',
    text:
      'Smart search option to reach the right parts. Navigate through part number or car details.',
  },
  {
    id: 2,
    imgSrc: Winner,
    altText: 'Orders',
    title: 'Orders',
    text:
      'Place order via call, photo or part number and track your orders. Submit new part requests too.',
  },
  {
    id: 3,
    imgSrc: Cloud,
    altText: 'Account & Outstanding',
    title: 'Account & Outstanding',
    text:
      'View all your invoices, sales returns and outstanding of various distributors, all in one place.',
  },
  {
    id: 4,
    imgSrc: Setting,
    altText: 'Stock',
    title: 'Stock',
    text:
      'View availability of parts at different branches to reduce turn-around-time in order procurement.',
  },
  {
    id: 5,
    imgSrc: Design,
    altText: 'Schemes',
    title: 'Schemes',
    text:
      'Be informed about the promotions and schemes of various brands in real-time and track progress.',
  },
  {
    id: 6,
    imgSrc: Chat,
    altText: 'E-Wallet',
    title: 'E-Wallet',
    text:
      'Collect scheme incentives to be used in upcoming orders and for quick payments at the distributor.',
  },
];

export default function FeatureWorkshops() {
  return (
    <section sx={{ variant: 'section.feature' }}>
      <Container>
        <SectionHeader
          slogan="Workshop features"
          title="Feature Highlights to get you started"
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <FeatureCard
              key={item.id}
              src={item.imgSrc}
              alt={item.title}
              title={item.title}
              text={item.text}
            />
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  grid: {
    pt: [0, null, null, null, null, null, null, 3],
    gridGap: [
      '37px 0',
      null,
      '45px 30px',
      null,
      '50px 30px',
      null,
      null,
      '90px 70px',
    ],
    width: ['100%', '80%', '100%'],
    mx: 'auto',
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      null,
      'repeat(3,1fr)',
    ],
  },
};
